@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Raleway';
}

@font-face {
  font-family: 'Scotch';
  src: url(../public/scotch.ttf);
}

